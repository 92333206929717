
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Trebuchet MS, sans-serif
}

body {
	background: linear-gradient(90deg, #c3d1e4, #1c6176);	
}

.container {
	align-items: center;
}

.screen-container {
	display: flex;
	align-items: center;
	justify-content: center;
	/* min-height: 50vh; */
}

.image-container {
    background: url(https://fuel.digital/wp-content/uploads/2022/06/fuel-digital-logo.svg) no-repeat center;
    object-fit: cover;
    display: flex;
    margin: auto;
    height:12rem;
}

.screen {		
	/* background: linear-gradient(90deg, #204353, #a4a4a4);		 */
    
	background-color: #e3e3e3;	
	position: relative;	
	height: 100%;
	width: 360px;	
	box-shadow: 0px 0px 24px #4d4d4d;
}

.screen-content {
	z-index: 1;
	position: relative;	
	height: 100%;
    margin:auto;
}

.input-form {
	width: 320px;
	padding: 30px;
	/* padding-top: 156px; */
    margin: auto;
}

.input-container, .input-container-dropdown {
	padding: 20px 0px;	
	position: relative;	
    margin-left: -1.5rem;
}


.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.text-input {
	border: none;
	border-bottom: 2px solid #a9a9a9;
	background: none;
	padding: 10px;
	padding-left: 6px;
	font-weight: 700;
	width: 100%;
	transition: .2s;
}

.text-input:active,
.text-input:focus,
.text-input:hover {
	outline: none;
	border-bottom-color: #1c6176;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.button {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding-top: 16px;
    padding-bottom: 16px;
	border-radius: 26px;
	border: 1px solid #a9a9a9;
	text-transform: uppercase;
	font-weight: 700;
	display: absolute;
	align-items: center;
	width: 100%;
	color: #444444;
	box-shadow: 0px 2px 2px #a9a9a9;
	cursor: pointer;
	transition: .2s;
}

.button:active,
.button:focus,
.button:hover {
	border-color: #a9a9a9;
	outline: none;
}

.api-button {
	background: none;
    font-size: 14px;
	margin-top: 30px;
	padding-top: 4px;
    padding-bottom: 4px;
	border-radius: 10px;
	border: none;
	text-transform: uppercase;
	font-weight: 700;
	display: absolute;
	align-items: center;
	width: 100%;
	color: #444444;
	transition: .2s;
}

.api-image {
	background: none;
    border: none;
    opacity: 1;
    display: flex;
    align-items: center;
	cursor: pointer;
    width:100%
}

.api-image-minus {
	background: none;
    border: none;
    opacity: 1;
    display: flex;
    align-items: center;
	cursor: pointer;
    width:100%
}

.help-container {
    object-fit: cover;
    display: flex;
    margin: auto;
    height: 3rem;
    padding-top: 1rem;
	cursor: pointer;
}

.help-button {
    background: none;
    border: none;
    opacity: 1;
    display: flex;
    align-items: center;
    width:100%
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.modal-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}

.test-API-container {
    margin-bottom: 0;
    width: 100%;
    max-width: 20rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    display: flex;
	align-items: center;
	justify-content: center;
    margin: auto;

}

.test-API-container input {
    padding: 0.7rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #eaeaea;
    width: 100%;
    transition: all 0.3s ease-in-out;
    display: flex;
	color:#444444;
	font-weight: 600;
	text-align: center;
    /* object-fit: cover;
    display: flex;
    margin: auto;
    height: 3rem;
    padding-top: 1rem;
	cursor: pointer; */
}

.test-API-container button {
    padding: 0.7rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #eaeaea;
    width: 25%;
    transition: all 0.3s ease-in-out;
    display: flex;
	color:#444444;
	text-align: center;
	cursor: pointer;
	text-transform: uppercase;
    
	font-weight: 600;
}

.test-API-container input:focus {
    border-color: #1c738d;
    box-shadow: 0 0 5px #1a373f
}

/* .test-API-button {
	background: #fff;
	text-transform: uppercase;
	display: absolute;
	align-items: center;
	width: 100%;
	color: #444444;
	transition: .2s;
} */